import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Grid,
  Popover
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './style.scss';
import _ from 'lodash';

const Comments = ({ comments, user, section, architectComments, medicalSupplyAssistantComments }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [popoverContent, setPopoverContent] = useState([]);
  const [timelineUsers, setTimelineUsers] = useState([]);
  const handleClickPopover4 = (event, userComments) => {
    setAnchorEl4(event.currentTarget);
    setPopoverContent(userComments);
  };
  const handleClosePopover4 = () => {
    setAnchorEl4(null);
  };
  const [anchorEl4, setAnchorEl4] = useState(null);
  const open4 = Boolean(anchorEl4);
  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    if (comments?.length) {
      let tasks = [];
      comments.forEach((task) => {
        let clonedTask = _.cloneDeep(task);
        if (task.comment) {
          clonedTask.user = task.users[0];
          tasks.push(clonedTask);
        }
      });
      let result = tasks.reduce(function (previousValue, currentValue) {
        previousValue[currentValue.user?.username] =
          previousValue[currentValue.user?.username] || [];

        previousValue[currentValue.user?.username].push(currentValue);

        return previousValue;
      }, {});

      setTimelineUsers(result);
    }
  }, [comments]);

  if (architectComments?.length > 0 && section === 'architect' ) {
    return (
      <>
      {
        architectComments.map((comment, index) => (
            <Grid
              item
              container
              md={4}
              className="mt-3 "
              key={index}>
              <Grid item md={6} className="ml-3 mr-2">
                <p className="username-title mb-0">{comment.author}</p>
                <p className="role-title">
                  {comment.step}
                </p>
              </Grid>
              <Grid item md={4}>
                <Button
                  className="m-2 comments-btn pt-0 pb-0 pl-2 pr-1"
                  onClick={(e) =>
                    handleClickPopover4(e, architectComments[index])
                  }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 512 512"
                    className="mr-2 ml-2">
                    <path
                      d="M256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2l-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29c7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1l-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160s-93.3 160-208 160z"
                      fill="currentColor"
                    />
                  </svg>
                  <span>
                    <b>التعليقات</b> 
                    {/* {timelineUsers[username].length}) */}
                    <ExpandMoreIcon />
                  </span>
                </Button>
                <Popover
                  open={open4}
                  anchorEl={anchorEl4}
                  classes={{
                    paper:
                      'popover-custom-wrapper rounded popover-custom-md mt-2'
                  }}
                  onClose={handleClosePopover4}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                  PaperProps={{
                    style: {
                      width: '30%',
                      borderRadius: '5px !important'
                    }
                  }}>
                  <Box className="p-4">
                    {/* {
                    popoverContent.map((task, index) => {*/}
                          {popoverContent.comment && (
                            <div>
                              <div className="d-flex justify-content-center">
                                <h6 className="step-font">
                                  <b>الخطوة: </b>
                                  {popoverContent.step}
                                </h6>
                              </div>
                              <div className="d-flex justify-content-center">
                                <p className="comment-font">
                                  <b>الملاحظات: </b> {popoverContent.comment}
                                </p>
                              </div>

                              <div className="d-flex justify-content-center">
                                <span className="date-font mb-2">
                                  {new Date(
                                    popoverContent.date
                                  ).toLocaleDateString('ar-eg', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric'
                                  })}
                                </span>
                              </div>
                              {/* {index !=
                                popoverContent.length - 1 && (
                                  <Divider
                                    style={{ background: '#7A81A1' }}
                                    className="m-3"
                                  />
                                )} */}
                            </div>
                          )}
                      {/* }  */}

                     {/* )}  */}
                  </Box>
                </Popover>
              </Grid>
            </Grid>
          
        ))
      }
    </>
    )
  } else if(medicalSupplyAssistantComments?.length > 0 && section === 'assistant secretary general for medical supply') {
    return (
      <>
      {
        medicalSupplyAssistantComments.map((comment, index) => (
            <Grid
              item
              container
              md={4}
              className="mt-3 "
              key={index}>
              <Grid item md={6} className="ml-3 mr-2">
                <p className="mt-2">{comment.author}</p>
                {/* <p className="role-title">
                  {comment.step}
                </p> */}
              </Grid>
              <Grid item md={4}>
                <Button
                  className="m-2 comments-btn pt-0 pb-0 pl-2 pr-1"
                  onClick={(e) =>
                    handleClickPopover4(e, medicalSupplyAssistantComments[index])
                  }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 512 512"
                    className="mr-2 ml-2">
                    <path
                      d="M256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2l-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29c7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1l-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160s-93.3 160-208 160z"
                      fill="currentColor"
                    />
                  </svg>
                  <span>
                    <b>التعليقات</b> 
                    <ExpandMoreIcon />
                  </span>
                </Button>
                <Popover
                  open={open4}
                  anchorEl={anchorEl4}
                  classes={{
                    paper:
                      'popover-custom-wrapper rounded popover-custom-md mt-2'
                  }}
                  onClose={handleClosePopover4}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                  PaperProps={{
                    style: {
                      width: '30%',
                      borderRadius: '5px !important'
                    }
                  }}>
                  <Box className="p-4">
                          {popoverContent.comment && (
                            <div>
                              <div className="d-flex justify-content-center">
                                <p className="comment-font">
                                  <b>الملاحظات: </b> {popoverContent.comment}
                                </p>
                              </div>

                              <div className="d-flex justify-content-center">
                                <span className="date-font mb-2">
                                  {new Date(
                                    popoverContent.date
                                  ).toLocaleDateString('ar-eg', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: 'numeric'
                                  })}
                                </span>
                              </div>
                            </div>
                          )}
                  </Box>
                </Popover>
              </Grid>
            </Grid>
          
        ))
      }
    </>
    )
  } else {
    return (
      <>
          {!_.isEmpty(timelineUsers) && (
            <div className="accordion mb-spacing-6-x2">
              <Card className={clsx('card-box ', { 'panel-open': isOpen })}>
                <Card>
                  <div className="card-header">
                    <div className="panel-title">
                      <div className="accordion-toggle">
                        <Button
                          variant="text"
                          size="large"
                          className="btn-link d-flex align-items-center justify-content-between btn-transition-none"
                          onClick={toggle}>
                          <span className="comments-title">التعليقات</span>
                          {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <Collapse in={isOpen} className="content">
                    <Grid container>
                      {Object.keys(timelineUsers).map((username, index) => {
                        return (
                          timelineUsers[username].length > 0 && (
                            <Grid
                              item
                              container
                              md={4}
                              className="mt-3 "
                              key={index}>
                              <Grid item md={6} className="ml-3 mr-2">
                                <p className="username-title mb-0">{username}</p>
                                <p className="role-title">
                                  {timelineUsers[username][0].step.name}
                                </p>
                              </Grid>
                              <Grid item md={4}>
                                <Button
                                  className="m-2 comments-btn pt-0 pb-0 pl-2 pr-1"
                                  onClick={(e) =>
                                    handleClickPopover4(e, timelineUsers[username])
                                  }>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    width="1em"
                                    height="1em"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 512 512"
                                    className="mr-2 ml-2">
                                    <path
                                      d="M256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2l-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29c7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1l-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160s-93.3 160-208 160z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                  <span>
                                    <b>التعليقات</b> (
                                    {timelineUsers[username].length})
                                    <ExpandMoreIcon />
                                  </span>
                                </Button>
                                <Popover
                                  open={open4}
                                  anchorEl={anchorEl4}
                                  classes={{
                                    paper:
                                      'popover-custom-wrapper rounded popover-custom-md mt-2'
                                  }}
                                  onClose={handleClosePopover4}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center'
                                  }}
                                  PaperProps={{
                                    style: {
                                      width: '30%',
                                      borderRadius: '5px !important'
                                    }
                                  }}>
                                  <Box className="p-4">
                                    {popoverContent.map((task, index) => {
                                      return (
                                        <>
                                          {task.comment && (
                                            <div>
                                              <div className="d-flex justify-content-center">
                                                <h6 className="step-font">
                                                  <b>الخطوة: </b>
                                                  {task.step.name}
                                                </h6>
                                              </div>
                                              <div className="d-flex justify-content-center">
                                                <p className="comment-font">
                                                  <b>الملاحظات: </b> {task.comment}
                                                </p>
                                              </div>

                                              <div className="d-flex justify-content-center">
                                                <span className="date-font mb-2">
                                                  {new Date(
                                                    task.updatedAt
                                                  ).toLocaleDateString('ar-eg', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: 'numeric'
                                                  })}
                                                </span>
                                              </div>
                                              {index !=
                                                popoverContent.length - 1 && (
                                                <Divider
                                                  style={{ background: '#7A81A1' }}
                                                  className="m-3"
                                                />
                                              )}
                                            </div>
                                          )}
                                        </>
                                      );
                                    })}
                                  </Box>
                                </Popover>
                              </Grid>
                            </Grid>
                          )
                        );
                      })}
                    </Grid>
                  </Collapse>
                </Card>
              </Card>
            </div>
          )}   
      </>
    );
  }
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    architectComments: state.details?.task?.submission?.values?.architect?.comments,
    medicalSupplyAssistantComments: state.details?.task?.submission?.values?.medicalSupplyAssistant?.comments
  };
};
export default connect(mapStateToProps)(Comments);
