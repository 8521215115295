import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Grid, StylesProvider, jssPreset, Card } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  assignTask,
  getTaskDetails,
  getTimeline,
  getUsers
} from '../../reducers/details/detailsAction';
import { create } from 'jss';
import rtl from 'jss-rtl';
import Tabs from '../../components/Tabs';
import GridCards from '../../components/GridCard';
import Comments from '../../components/Comments';
import ConfirmModal from '../../components/ConfirmModal';
import FormModal from '../../components/FormModal';
import StepInputModal from '../../components/StepInputModal';
import TaskTimeline from '../../components/TaskTimeLine';
import client from '../../api/apiAuth/guestClient';
import './index.css';
import { GRIDCARD_TYPES, CARD_STYLES } from '../../components/GridCard/types';
import FILE_ACCORDION_TYPE from '../../components/FilesAccordion/types';
import _ from 'lodash';
import { saveCardAttachment } from '../../reducers/details';
import { handleTaskAssignment } from '../../reducers/details/detailsAction';
import { SyncLoader } from 'react-spinners';
import BlockUi from 'react-block-ui';
import DetailsButtons from '../../components/DetailsComponents/DetailsButtons';
import AssignmentComponent from '../../components/DetailsComponents/AssignmentComponent';
import PreviousSubmissions from 'components/DetailsComponents/PreviousSubmissions';
import { setSubmission } from 'reducers/form/formActions';
import { Alert, AlertTitle } from '@mui/material';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function Details(props) {
  const {
    getTaskDetails,
    getTimeline,
    assignTask,
    task,
    timeline,
    user,
    saveCardAttachment,
    taskLoading,
    setSubmission
  } = props;
  const submissionId = props.match.params.id;
  const [cards, setCards] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [attach, setAttach] = useState();
  const [legalDocs, setLegalDocs] = useState();
  const [medicalDocs, setMedicalDocs] = useState();
  const [finDocs, setFinDocs] = useState();
  const [architectDocs, setArchitectDocs] = useState();
  const [modalInfo, setModalInfo] = useState({
    question: '',
    message: ''
  });
  const [formModal, setFormModal] = useState(false);
  const [stepInputModal, setStepInputModal] = useState(false);
  const [pdfModals, setPdfModals] = useState([]);
  const [filteredAttachments, setFilteredAttachments] = useState({
    primary: [],
    relatives: [],
    social: [],
    reason: [],
    family: [],
    education: [],
    disability: [],
    grim: [],
    health: [],
    housing: [],
    human: [],
    mariage: [],
    orphan: [],
    docs: []
  });
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const history = useHistory();
  // Set a dismissable alert notifying the user of a previous reviewer's comment

  const handleAssignment = () => {
    assignTask({
      submission_id: submissionId,
      task_status: 'Pending',
      step_id: task.step_id
    }).then((res) => {
      window.location.reload();
    });
  };

  const onAttachmentReviewed = (attachment, key, e, setLoading) => {
    if (!(key in filteredAttachments)) return;
    let isReviewed = e.target.checked;
    setLoading(true);
    client
      .get(`attachment/reviewAttachment`, {
        params: {
          id: attachment.data.id,
          is_reviewed: +e.target.checked
        }
      })
      .then(() => {
        setFilteredAttachments((previousAttachments) => {
          return {
            ...previousAttachments,
            [key]: previousAttachments[key].map((previousAttachment) => {
              let clonedAttachment = _.cloneDeep(previousAttachment);
              if (clonedAttachment.data.id == attachment.data.id)
                clonedAttachment.data.is_reviewed = isReviewed;
              return clonedAttachment;
            })
          };
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getTaskDetails(submissionId);
    getTimeline({ submissionId });
  }, [submissionId]);
  
  useEffect(() => {
    // Filter attachments for each card
    const filterAttachments = (attachments, documentIds, objectName) => {
      const filtered = [];
      for (let i = 0; i < attachments.length; i++) {
        if (objectName) {
          if (
            documentIds.includes(attachments[i].input_id) &&
            attachments[i].name.startsWith(objectName)
          ) {
            filtered.push({
              data: attachments[i],
              idx: i
            });
          }
        } else {
          if (documentIds.includes(attachments[i].input_id)) {
            filtered.push({
              data: attachments[i],
              idx: i
            });
          }
        }
      }
      return filtered;
    };
    if (task?.submission.attachments) {
      task?.submission.attachments.forEach((attach) => {
        switch (attach.name?.split('.')[0]) {
          case 'medical_request_docs': {
            setMedicalDocs({
              key: 'مرفقات الادارة الطبية'
            });
            break;
          }
          case 'financial_request_docs': {
            setFinDocs({
              key: 'مرفقات الادارة المالية'
            });
            break;
          }
          case 'legal_request_docs': {
            setLegalDocs({
              key: 'مرفقات الادارة القانونية'
            });
            break;
          }
          case 'architect': {
            setArchitectDocs({
              key: 'مرفقات المهندس المعماري'
            });
            break;
          }
        }
      });
    }

    let filteredAttachments = null;
    if (task && 'submission' in task) {
      /* Indexes
        0 Primary
        1 Relatives
        2 Medical Condition, Residence Status, Income, Expenses
        3 Request Reason
      */
      filteredAttachments = {
        primary: filterAttachments(
          task.submission.attachments,
          [327],
          'primaryFields'
        ),
        relatives: filterAttachments(
          task.submission.attachments,
          [344, 15, 340, 346],
          'relatives'
        ),
        //مستندات التقييم اﻻجتماعى
        social: filterAttachments(task.submission.attachments, [
          338,
          336,
          335,
          334,
          333,
          328,
          329,
          330,
          331,
          332,
          337,
          343
        ]),
        debtDetails: filterAttachments(task.submission.attachments, [378]),
        //مستندات غرض الطلب
        reason: filterAttachments(task.submission.attachments, [341, 342]),
        // مستندات التقديم
        docs: filterAttachments(task.submission.attachments, [
          379,
          347,
          348,
          349,
          350,
          351,
          367,
          352,
          353,
          354,
          355,
          356,
          357,
          365,
          358,
          359,
          360,
          361,
          363,
          364,
          368,
          369,
          370,
          372,
          371,
          373,
          374,
          375,
          376,
          366,
          362,
          377,
          445,
          444,
          443,
          442,
          441,
          440,
          439,
          438,
          437,
          436,
          435,
          434,
          433,
          432,
          431,
          453,
          452,
          451,
          450,
          449,
          430,
          429,
          428,
          427,
          426,
          425,
          424,
          423,
          422,
          421,
          420,
          419,
          418,
          417,
          416,
          415,
          414,
          413,
          412,
          411,
          410,
          409,
          408,
          407,
          406,
          405,
          404,
          403,
          402,
          448,
          447,
          446,
          454,
          455,
          456,
          457,
          458,
          459,
          460,
          461,
          462,
          463,
          464,
          465,
          466,
          467,
          468,
          469,
          470,
          471,
          478,
          479,
          480,
          481,
          482,
          483,
          484,
          485,
          486,
          487,
          488,
          489,
          490,
          491,
          492,
          493,
          494,
          495,
          496,
          521,
          522,
          523,
          524,
          525,
          526,
          527,
          528,
          529,
          530,
          531,
          532,
          533,
          534,
          535,
          536,
          537,
          538,
          539,
          540,
          541,
          542,
          543,
          544,
          545,
          546,
          547,
          553
        ]),
        //مستندات قانونية
        legalDocs: filterAttachments(task?.submission.attachments, [507, 508]),
        //مستندات مالية
        financialDocs: filterAttachments(task?.submission.attachments, [
          509,
          510,
          511
        ]),
        //مستندات طبية
        medicalDocs: filterAttachments(task?.submission.attachments, [
          512,
          513,
          514
        ]),
        //مستندات المهندس المعماري
        architectDocs: filterAttachments(task?.submission.attachments, [557])
      };
      const contactMapping = {};
      contactMapping[task.submission.contacts.id] =
        task.submission.contacts.name;
      const relatives = task.submission.contacts.contacts.relatives;
      if (relatives) {
        for (const relative of relatives) {
          contactMapping[relative.id] = relative.name;
        }
      }

      const data = [];
      const extras = [];
      for (const attachment of filteredAttachments.relatives) {
        if (!attachment.data.contact_id) {
          extras.push(attachment);
          continue;
        }
        let found = false;
        for (const item of data) {
          if (item.contact_id === attachment.data.contact_id) {
            item.items.push(attachment);
            found = true;
            break;
          }
        }
        if (!found) {
          data.push({
            contact_name: contactMapping[attachment.data.contact_id],
            items: [attachment],
            contact_id: attachment.data.contact_id
          });
        }
      }
      if (data.length) {
        for (const extra of extras) {
          data[data.length - 1].items.push(extra);
        }
      }

      //filteredAttachments.relatives = data;

      setFilteredAttachments(filteredAttachments);
    }
    task?.submission.values.notes
      ? !_.isEmpty(task?.submission.values.notes)
        ? setAttach(task?.submission.values.notes)
        : setAttach({
            key: 'الملفات المرفقة'
          })
      : setAttach({
          key: 'الملفات المرفقة'
        });

    setPdfModals(task?.submission.attachments.map((e) => false));
    // Show notification if there is a comment
  }, [task]);
  
  useEffect(() => {
    let cards = [
      [
        {
          title: task?.submission.contacts.name,
          attrs: [
            { key: 'values.applicant', title: 'مقدم الطلب' },
            { key: 'values.groom_father_name', title: 'ولي أمر العروسة' },
            {
              key: 'values.groom_father_id',
              title: 'الرقم القومي لولي أمر العروسة'
            },
            { key: 'values.applicant_id', title: 'الرقم القومي لمقدم الطلب' },
            { key: 'values.applicant_phone', title: 'رقم مقدم الطلب' },
            {
              key: 'values.other_applicant_phone',
              title: 'رقم اخر لمقدم الطلب'
            },
            { key: 'values.groom_father_phone', title: 'رقم ولي امر العروسة' },
            {
              key: 'values.groom_father_phone_2',
              title: 'رقم اخر لولي امر العروسة'
            },
            {
              key: 'contacts.first_name',
              title: 'اسم المستفيد الاول'
            },
            {
              key: 'contacts.family_name',
              title: 'اسم المستفيد الاخير'
            },
            {
              key: 'contacts.contact_id',
              title: 'الرقم القومى / رقم الباسبور'
            },
            {
              key: 'contacts.nationality',
              title: 'الجنسية'
            },
            {
              key: 'contacts.phone',
              title: 'رقم موبايل'
            },
            {
              key: 'contacts.phone_secondary',
              title: 'رقم أرضى/موبايل اخر'
            },
            {
              key: 'contacts.gender',
              title: 'النوع'
            },
            {
              key: 'contacts.marital_status',
              title: 'الحالة الأجتماعية'
            },
            {
              key: 'contacts.religion',
              title: 'الديانة'
            },
            {
              key: 'contacts.birth_date',
              title: 'تاريخ الميلاد'
            },
            {
              key: 'contacts.age',
              title: 'السن'
            },
            { key: 'file_code', title: 'رقم الطلب' },
            {
              key: 'createdAt',
              title: 'تاريخ تسجيل الطلب'
            },
            { key: 'form.name', title: 'أسم البرنامج' },
            { key: 'values.request_source', title: 'مصدر الطلب' },
            { key: 'values.other_request_source', title: 'مصدر الطلب' },
            { key: 'values.payment_card', title: 'كارت ميزة' }
          ],
          secondaryAttrs: [
            {
              key: 'contacts.address',
              title: 'العنوان المذكور فى البطاقة'
            },
            {
              key: 'contacts.place_of_residence',
              title: 'العنوان الحالى'
            },
            {
              key: 'contacts.special_address_mark',
              title: 'علامة مميزة للعنوان'
            },
            {
              key: 'contacts.governorate',
              title: 'المحافظة'
            },
            {
              key: 'contacts.administrative_center',
              title: 'أسم القسم / المركز'
            },
            {
              key: 'contacts.village',
              title: 'أسم القرية / الشياخة'
            },
            { key: 'contacts.center', title: 'المركز' },
            {
              key: 'contacts.education',
              title: 'التعليم '
            },
            {
              key: 'contacts.education_type',
              title: ' نوع التعليم'
            },
            {
              key: 'contacts.school_education',
              title: ' نوع التعليم المدرسى'
            },
            {
              key: 'contacts.university_education',
              title: ' نوع التعليم الجامعى '
            },
            { key: 'contacts.job', title: 'العمل' },
            { key: 'contacts.job_type', title: 'طبيعة حالة العمل' },
            { key: 'contacts.job_stability', title: 'الانتظام في العمل' },
            { key: 'contacts.work_place', title: 'قطاع العمل' },
            {
              key: 'contacts.social_insurances',
              title: 'التأمينات الأجتماعية'
            },
            {
              key: 'contacts.economic_activity_sector',
              title: 'قطاع النشاط الاقتصادي'
            },
            { key: 'contacts.disability_status', title: 'الأعاقة' },
            { key: 'contacts.disability_type', title: 'نوع الإعاقة' },
            { key: 'contacts.disability_percentage', title: 'نسبة الإعاقة' },
            { key: 'contacts.health_insurance', title: 'التأمين الصحى' },
            { key: 'contacts.chronic_disease', title: 'مرض مزمن' },
            { key: 'contacts.national_cost', title: 'نفقة الدولة' },
            { key: 'values.primaryFields_notes', title: 'ملاحظات' },
            { key: 'contacts.household_head', title: 'رب الاسرة' },
            { key: 'contacts.notes', title: 'ملاحظات' }
          ],
          secondaryTitle: 'البيانات الاولية',
          gridSize: 4,
          size: 5,
          secondarySize: 5,
          // style: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
          form: task?.submission,
          accordionType: FILE_ACCORDION_TYPE.DEFAULT,
          type: GRIDCARD_TYPES.DUAL,
          attachments:
            filteredAttachments && 'primary' in filteredAttachments
              ? filteredAttachments.primary
              : null,
          onAttachmentReviewed: onAttachmentReviewed,
          attachmentsKey: 'primary'
        }
      ],
      [
        {
          title: 'قسم تكلفة الخدمة',
          attrs: [
            { key: 'service_type', title: 'نوع الخدمة' },
            { key: 'comitee_no', title: 'رقم اللجنة' },
            { key: 'service_location', title: 'حوالة' },
            { key: 'service_details', title: 'تفاصيل الخدمة' },
            { key: 'medical_opinion', title: 'الرأي الطبي' },
            { key: 'diagnose', title: 'التشخيص' },
            { key: 'required_pounds', title: 'المبلغ المطلوب بالجنيه' },
            { key: 'payment_card', title: 'كارت ميزة' }
          ],
          size: 12,
          gridSize: 2,
          divider: true,
          form: task?.submission.values.requestPurpose,
          isMany: true,
          type: GRIDCARD_TYPES.DEFAULT,
          accordionType: FILE_ACCORDION_TYPE.RELATIVE
        }
      ],
      [
        {
          title: 'التكوين الاسرى',
          attrs: [
            { key: 'name', title: 'الاسم الرباعى' },
            { key: 'address', title: 'العنوان' },
            { key: 'governorate', title: 'المحافظة' },
            { key: 'administrative_center', title: 'أسم القسم / المركز' },
            { key: 'village', title: 'أسم القرية / الشياخة' },

            { key: 'contact_id', title: 'الرقم القومى / رقم الباسبور' },
            {
              key: 'nationality', title: 'الجنسية' },
            {
              key: 'relation',
              title: 'صلة القرابة'
            },
            { key: 'age', title: 'السن' },
            ,
            { key: 'job', title: 'العمل' },
            {
              key: 'marital_status',
              title: 'الحالة الاجتماعية'
            },
            { key: 'phone', title: 'رقم التيليفون' },
            {
              key: 'marriage_certificate_date',
              title: 'تاريخ وثيقة الزواج'
            },
            {
              key: 'income_value',
              title: 'قيمة مصادر الدخل'
            },
            {
              key: 'expenses_value',
              title: 'قيمة اﻹنفاقات'
            },
            {
              key: 'health_status',
              title: 'الحالة الصحية'
            },
            {
              key: 'gender',
              title: 'النوع'
            },
            {
              key: 'education',
              title: 'التعليم '
            },
            {
              key: 'education_type',
              title: ' نوع التعليم'
            },
            {
              key: 'school_education',
              title: ' نوع التعليم المدرسى'
            },
            {
              key: 'university_education',
              title: ' نوع التعليم الجامعى '
            },
            {
              key: 'job_type',
              title: 'طبيعة حالة العمل'
            },
            {
              key: 'job_stability',
              title: 'الانتظام في العمل'
            },
            {
              key: 'work_place',
              title: 'قطاع العمل'
            },
            {
              key: 'social_insurances',
              title: 'التامينات الاجتماعية'
            },
            {
              key: 'economic_activity_sector',
              title: 'قطاع النشاط الاقتصادي'
            },
            {
              key: 'health_insurance',
              title: 'التامين الصحى'
            },
            {
              key: 'chronic_disease',
              title: 'مرض مزمن'
            },
            {
              key: 'disability_status',
              title: 'الاعاقة'
            },
            {
              key: 'imprisoned',
              title: 'مسجون'
            },
            {
              key: 'notes',
              title: 'ملاحظات'
            },
            { key: 'household_head', title: 'رب الاسرة' }
          ],
          size: 12,
          gridSize: 2,
          divider: true,
          form: task?.submission.contacts.contacts.relatives,
          isMany: true,
          type: GRIDCARD_TYPES.DEFAULT,
          attachments:
            filteredAttachments && 'relatives' in filteredAttachments
              ? filteredAttachments.relatives
              : null,
          accordionType: FILE_ACCORDION_TYPE.DEFAULT,
          onAttachmentReviewed: onAttachmentReviewed,
          attachmentsKey: 'relatives'
        }
      ],
      [
        {
          title: 'الوثائق القانونية',
          attrs: [{ key: 'procedure', title: 'الاجراء' }],
          size: 12,
          gridSize: 2,
          divider: true,
          form: task?.submission.values.requiredProcedures,
          isMany: true,
          type: GRIDCARD_TYPES.DEFAULT,
          accordionType: FILE_ACCORDION_TYPE.RELATIVE
        }
      ],
    ];
    if (task?.submission.values.general_sec_notes) {
      cards.unshift([
        {
          title: 'ملاحظات اﻷمين العام (طلب سريع)',
          size: 12,
          text: task.submission.values.general_sec_notes,
          type: GRIDCARD_TYPES.GENERALSECNOTES
        }
      ]);
    }
    setCards(cards);
    setTabs([
      {
        title: 'غرض الطلب',
        cards: [
          {
            attrs: [
              {
                key: 'comment',
                title: 'سبب وقف اﻻستحقاق',
                gridSize: 12
              },
              {
                key: 'service_name',
                title: 'الخدمة',
                gridSize: 3,
                style: { color: '#efb24e', fontWeight: 'bold' }
              },
              { key: 'category', title: 'الفئة' },
              { key: 'sub_category', title: 'الفئة الفرعية' },
              {
                key: 'payment_method',
                title: 'طريقة الدفع'
              },
              {
                key: 'agency_name',
                title: 'جهة التنفيذ'
              },
              { key: 'cost', title: 'التكلفة' },
              {
                key: 'actual_cost',
                title: 'التكلفة الفعلية'
              },
              {
                key: 'exchange_repeat',
                title: 'تكرار الصرف'
              },
              {
                key: 'payment_period',
                title: 'مدة الصرف (شهرياً)'
              }
            ],
            size: 12,
            gridSize: 3,
            form: task?.submission.requestPurpose,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER
          }
        ],
        attachments:
          filteredAttachments && 'reason' in filteredAttachments
            ? filteredAttachments.reason
            : null,
        onAttachmentReviewed: onAttachmentReviewed,
        attachmentsKey: 'reason'
      },
      {
        title: 'التقييم الأجتماعى ',
        cards: [
          {
            title: 'وصف مسكن الأسرة',
            attrs: [
              { key: 'housing', title: 'ملكية السكن' },
              {
                key: 'house_rent',
                title: 'الايجار',
                postfix: 'جنيها مصريا'
              },
              { key: 'housing_status', title: 'نوع حوائط المسكن' },
              {
                key: 'other_housing_status',
                title: 'نوع حوائظ  المسكن الاخرى'
              },
              {
                key: 'rooms_number',
                title: 'عدد حجرات السكن'
              },
              {
                key: 'furniture_status',
                title: 'حالة الاثاث '
              },

              {
                key: 'residential_floor_type',
                title: 'نوع ارضية السكن'
              },
              {
                key: 'water_source',
                title: 'مصدر المياه'
              },

              {
                key: 'other_water_source',
                title: 'مصادر المياه الاخرى'
              },
              {
                key: 'w_c',
                title: 'وجود دورة مياه'
              },
              {
                key: 'sewage',
                title: 'الاتصال بالصرف الصحى'
              },

              {
                key: 'other_sewage',
                title: 'اتصال بالصرف الصحى اخر'
              },
              {
                key: 'kitchen_fuel',
                title: 'نوع الوقود المستخدم في المطبخ'
              },
              {
                key: 'other_kitchen_fuel',
                title: 'نوع اخر من الوقود'
              },

              {
                key: 'housing_type',
                title: 'نوع المسكن'
              },
              {
                key: 'other_housing_type',
                title: 'نوع سكن اخر'
              },
              {
                key: 'residential_roof_type',
                title: 'نوع سقف المسكن'
              },
              {
                key: 'other_residential_roof_type',
                title: 'نوع اخر لسقف المنزل'
              },
              {
                key: 'housing_with_water',
                title: 'اتصال المنزل بالمياه'
              },

              {
                key: 'wc_type',
                title: 'نوع دورة المياه'
              },
              {
                key: 'other_wc_type',
                title: 'نوع دورة مياه اخر'
              },
              {
                key: 'kitchen_exist',
                title: 'وجود مطبخ'
              },
              {
                key: 'housingStatus_notes',
                title: 'ملاحظات '
              }
            ],
            secondaryTitle: 'ممتلكات الأسرة',
            secondaryAttrs: [
              { key: 'cooker_exists', title: 'وجود بوتجاز' },
              {
                key: 'cooker_status',
                title: 'حالة البوتجاز'
              },
              { key: 'washing_machine_exists', title: 'وجود غسالة' },
              {
                key: 'washing_machine_status',
                title: 'حالة الغسالة'
              },
              {
                key: 'fridge_exists',
                title: 'وجود ثلاجة'
              },
              {
                key: 'fridge_status',
                title: 'حالة الثلاجة '
              },

              {
                key: 'phone_exists',
                title: 'وجود تلفون'
              },
              {
                key: 'phone_status',
                title: 'حالة التلفون'
              },

              {
                key: 'tv_exists',
                title: 'وجود تلفزيون'
              },
              {
                key: 'tv_status',
                title: 'حالة التلفزيون'
              },
              {
                key: 'fan_exists',
                title: 'وجود مروحة'
              },

              {
                key: 'fan_status',
                title: 'حالة المروحة'
              },
              {
                key: 'air_conditioner_exists',
                title: 'وجود تكيف'
              },
              {
                key: 'air_conditioner_status',
                title: 'حالة التكيف'
              },

              {
                key: 'receiver_exists',
                title: 'وجود دش (ريسيفر/وصلة)'
              },
              {
                key: 'receiver_status',
                title: 'حالة الدش'
              },
              {
                key: 'heater_exists',
                title: 'وجود سخان'
              },
              {
                key: 'heater_status',
                title: 'حالة السخان'
              },
              {
                key: 'computer_exists',
                title: 'وجود كمبيوتر'
              },

              {
                key: 'computer_status',
                title: 'حالة الكمبيوتر'
              },
              {
                key: 'agricultural_property',
                title: 'حيازة زراعية'
              },
              {
                key: 'acres_number',
                title: 'عدد القراريط'
              },
              {
                key: 'cattle_heads',
                title: 'رؤوس ماشية'
              },
              {
                key: 'cattle_number',
                title: 'عدد رؤوس الماشية'
              },

              {
                key: 'goat_exists',
                title: 'وجود اغنام/ماعز'
              },
              {
                key: 'birds_exists',
                title: 'طيور داجنة '
              },
              {
                key: 'moto_exists',
                title: 'وجود موتوسيكل/تروسيكل'
              },
              {
                key: 'toktok_exists',
                title: 'وجود توكتوك'
              },
              {
                key: 'tractor_exists',
                title: 'وجود جرار زراعي'
              },
              {
                key: 'taxi_exists',
                title: 'وجود تاكسى'
              },
              {
                key: 'private_car_exists',
                title: 'وجود سيارة خاصة'
              },
              {
                key: 'transportation_car_exists',
                title: 'وجود سيارة نقل'
              },
              {
                key: 'familyProperty_notes',
                title: 'ملاحظات'
              }
            ],
            size: 5,
            gridSize: 6,
            secondarySize: 5,
            divider: true,
            style: {
              border: 'none',
              boxShadow: 'none'
            },
            type: GRIDCARD_TYPES.DUAL,
            form: task?.submission.values,
            cardStyle: CARD_STYLES.INNER
          },

          {
            title: 'مصادر الدخل',
            attrs: [
              {
                key: 'salaries',
                title: 'رواتب من العمل لافراد الاسرة',
                gridSize: 8,
                postfix: 'جنيها مصريا'
              },
              {
                key: 'salaries_income_type',
                title: 'نوع الدخل',
                gridSize: 4
              },
              {
                key: 'pension',
                title: 'معاش الشئون الاجتماعية',
                gridSize: 8,
                postfix: 'جنيها مصريا'
              },
              {
                key: 'pension_income_type',
                title: 'نوع الدخل',
                gridSize: 4
              },
              {
                key: 'insurance',
                title: 'معاش التأمينات الاجتماعية',
                gridSize: 8,
                postfix: 'جنيها مصريا'
              },
              {
                key: 'insurance_income_type',
                title: 'نوع الدخل',
                gridSize: 4
              },
              {
                key: 'charities',
                title: 'جمعيات خيرية اخرى',
                gridSize: 8,
                postfix: 'جنيها مصريا'
              },
              {
                key: 'charities_income_type',
                title: 'نوع الدخل',
                gridSize: 4
              },
              {
                key: 'other_income',
                title: 'اخرى',
                gridSize: 8,
                postfix: 'جنيها مصريا'
              },
              {
                key: 'other_income_type',
                title: 'نوع الدخل',
                gridSize: 4
              },
              {
                key: 'notes',
                title: 'ملاحظات',
                gridSize: 12
              }
            ],
            size: 6,
            gridSize: 12,
            style: {
              border: 'none',
              boxShadow: 'none'
            },
            form: task?.submission.values.incomes,
            divider: true,
            cardStyle: CARD_STYLES.INNER
          },
          {
            title: 'الأنفاقات',

            attrs: [
              {
                key: 'instalments',
                title: 'اقساط و مديونيات',
                postfix: 'جنيها مصريا'
              },
              {
                key: 'food_fees',
                title: 'مصاريف أطعام شهرى',
                postfix: 'جنيها مصريا'
              },
              {
                key: 'school_fees',
                title: 'مصاريف تعليمية و مدارس',
                postfix: 'جنيها مصريا'
              },
              {
                key: 'invoices',
                title: 'فواتير المرافق (ماء -غاز- كهرباء)',
                postfix: 'جنيها مصريا'
              },
              {
                key: 'other_expenditure',
                title: 'اخرى',
                postfix: 'جنيها مصريا'
              },
              {
                key: 'notes',
                title: 'ملاحظات',
                gridSize: 12
              }
            ],
            size: 6,
            gridSize: 12,
            divider: true,
            style: {
              border: 'none',
              boxShadow: 'none'
            },
            form: task?.submission.values.expenses,
            cardStyle: CARD_STYLES.INNER
          }
        ],

        attachments:
          filteredAttachments && 'social' in filteredAttachments
            ? filteredAttachments.social
            : null,
        onAttachmentReviewed: onAttachmentReviewed,
        accordionType: FILE_ACCORDION_TYPE.DEFAULT,
        attachmentsKey: 'social'
      },
      {
        // Project Program
        title: 'خصائص البرنامج',
        cards: [
          {
            attrs: [
              { key: 'project_type', title: 'نوع المشروع', gridSize: 4 },
              { key: 'other_project_type', title: 'نوع المشروع', gridSize: 4 },
              { key: 'project_value', title: 'قيمة المشروع', gridSize: 4 },
              { key: 'project_clause', title: 'الشروط', gridSize: 4 }
            ],
            size: 12,
            gridSize: 3,
            form: task?.submission.values.projectProperties,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER
          }
        ],
        attachments: null,
        onAttachmentReviewed: onAttachmentReviewed,
        attachmentsKey: 'debt'
      },
      {
        // Housing Program
        title: 'خصائص البرنامج',
        cards: [
          {
            attrs: [
              { key: 'program_housing_type', title: 'السكن', gridSize: 4 },
              {
                key: 'alt_housing_type',
                title: 'سكن بديل (إيجار : قديم/جديد)',
                gridSize: 4
              },
              { key: 'water_network', title: 'شبكات مياه', gridSize: 4 },
              {
                key: 'other_water_networks',
                title: 'شبكات مياه اخرى',
                gridSize: 4
              },
              {
                key: 'electric_devices',
                title: 'أجهزة كهربية',
                gridSize: 4,
                postfix: '/'
              },
              { key: 'washer_no', title: 'عدد الغسلات', gridSize: 4 },
              { key: 'heater_no', title: 'عدد السخانات', gridSize: 4 },
              { key: 'stove_no', title: 'عدد البوتجازات', gridSize: 4 },
              { key: 'fan_no', title: 'عدد المراوح', gridSize: 4 },
              { key: 'fridge_no', title: 'عدد الثلاجات', gridSize: 4 },
              {
                key: 'furniture_type',
                title: 'اثاث و مفروشات',
                gridSize: 4,
                postfix: '/'
              },
              { key: 'beds_no', title: 'عدد السراير', gridSize: 4 },
              { key: 'pillows_no', title: 'عدد المخدات', gridSize: 4 },
              { key: 'mattress_no', title: 'عدد المراتب', gridSize: 4 },
              { key: 'blanket_no', title: 'عدد البطانيات', gridSize: 4 },
              { key: 'closet_no', title: 'عدد الدواليب', gridSize: 4 },
              {
                key: 'other_furniture',
                title: 'اثاث و مفروشات اخرى',
                gridSize: 4
              },
              { key: 'others_no', title: 'عددهم', gridSize: 4 },
              {
                key: 'furniture_number',
                title: 'عدد قطع الاثاث او المفروشات',
                gridSize: 4
              },
              {
                key: 'university_housing_expenses_cost',
                title: 'قيمة المصروفات السكن الجامعي'
              }
            ],
            size: 12,
            gridSize: 3,
            form: task?.submission.values.housing_program_properties,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER
          }
        ],
        attachments: null,
        onAttachmentReviewed: onAttachmentReviewed,
        attachmentsKey: 'debt'
      },
      {
        // Health Program
        title: 'خصائص البرنامج',
        cards: [
          {
            attrs: [
              {
                key: 'medical_issuer',
                title: 'جهة اصدار التقرير الطبي',
                gridSize: 4
              },
              {
                key: 'other_medical_issuer',
                title: 'جهة اصدار التقرير الطبي',
                gridSize: 4
              },
              { key: 'patient_needs', title: 'احتياجات المريض ', gridSize: 4 },
              {
                key: 'other_patient_needs',
                title: 'احتياجات المريض ',
                gridSize: 4
              },
              {
                key: 'program_knowledge',
                title: 'معرفة المريض ببيت الزكاة',
                gridSize: 4
              },
              {
                key: 'other_knowlege_source',
                title: 'معرفة المريض ببيت الزكاة',
                gridSize: 4
              },
              {
                key: 'notes',
                title: ' ملاحظات',
                gridSize: 4
              }
            ],
            size: 12,
            gridSize: 3,
            form: task?.submission.values.health_program_properties,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER
          }
        ],
        attachments: null,
        onAttachmentReviewed: onAttachmentReviewed,
        attachmentsKey: 'health'
      },
      {
        // Disability Program
        title: 'خصائص البرنامج',
        cards: [
          {
            attrs: [
              { key: 'disability_type', title: 'نوع الإعاقة', gridSize: 4 },
              {
                key: 'motion_disability_type',
                title: 'نوع الإعاقة الحركية',
                gridSize: 4
              },
              {
                key: 'vision_disability_type',
                title: 'نوع الإعاقة البصرية',
                gridSize: 4
              },
              {
                key: 'specific_disability_type',
                title: 'نوع الإعاقة السمعية',
                gridSize: 4
              },
              {
                key: 'hearing_disability_cost',
                title: 'قيمة الجلسة',
                gridSize: 4
              },
              {
                key: 'hearing_disability_number',
                title: 'عدد الجلسات',
                gridSize: 4
              },
              {
                key: 'hearing_disability_total',
                title: 'الاجمالى',
                gridSize: 4
              },
              {
                key: 'retard_disability_type',
                title: 'نوع الإعاقة الذهنية',
                gridSize: 4
              },
              {
                key: 'notes',
                title: 'ملاحظات',
                gridSize: 12
              }
            ],
            size: 12,
            gridSize: 3,
            form: task?.submission.values.disability_program_properties,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER
          }
        ],
        attachments: null,
        onAttachmentReviewed: onAttachmentReviewed,
        attachmentsKey: 'debt'
      },
      {
        // Education Program
        title: 'خصائص البرنامج',
        cards: [
          {
            attrs: [
              {
                key: 'expatriate',
                title: 'وافد',
                gridSize: 4
              },
              {
                key: 'program_education_type',
                title: 'مستوى التعليم',
                gridSize: 4
              },
              { key: 'school_education', title: 'نوع التعليم', gridSize: 4 },
              {
                key: 'university_education',
                title: 'نوع التعليم',
                gridSize: 4
              },
              {
                key: 'university',
                title: 'الجامعة',
                gridSize: 4
              },
              {
                key: 'college',
                title: 'الكلية',
                gridSize: 4
              },
              {
                key: 'study_group',
                title: 'الفرقة الدراسية',
                gridSize: 4
              },
              {
                key: 'grade',
                title: 'التقدير',
                gridSize: 4
              },
              {
                key: 'other_support_inquiry',
                title: 'هل تتلقى دعمًا من جهات أخرى؟',
                gridSize: 4
              },
              {
                key: 'other_support',
                title: 'اذكر نوع هذا الدعم وقيمته',
                gridSize: 4
              },
              { key: 'study_year', title: 'السنة الدراسية', gridSize: 4 },
              { key: 'study_term', title: 'الترم الدراسي', gridSize: 4 },
              { key: 'study_expenses', title: 'مصروفات دراسية', gridSize: 4 },
              {
                key: 'study_expenses_value',
                title: 'قيمة المصروفات الدراسية',
                gridSize: 4
              },
              {
                key: 'study_tools',
                title: 'مستلزمات وأدوات دراسية',
                gridSize: 4
              },
              {
                key: 'other_study_tools',
                title: 'مستلزمات وأدوات دراسية',
                gridSize: 4
              },
              {
                key: 'university_housing_expenses',
                title: 'نوع السكن الجامعى',
                gridSize: 4
              },
              {
                key: 'university_housing_expenses_cost',
                title: 'قيمة المصروفات السكن الجامعي',
                gridSize: 4
              },
              {
                key: 'scholarship',
                title: 'منحة الطلاب المتفوقين',
                gridSize: 4
              },
              {
                key: 'notes',
                title: 'ملاحظات',
                gridSize: 12
              }
            ],
            size: 12,
            gridSize: 3,
            form: task?.submission.values.education_program_properties,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER
          }
        ],
        attachments: null,
        onAttachmentReviewed: onAttachmentReviewed,
        attachmentsKey: 'debt'
      },
      {
        // Debt program
        title: 'خصائص البرنامج',
        cards: [
          {
            attrs: [
              {
                key: 'dept_name',
                title: 'اسم الدائن/ة',
                gridSize: 3
                // style: { color: '#efb24e', fontWeight: 'bold' }
              },
              {
                key: 'debt_phone',
                title: 'تلفون الدائن/ة',
                gridSize: 3
                // style: { color: '#efb24e', fontWeight: 'bold' }
              },
              {
                key: 'debt_number',
                title: 'عددالدائنين/الدائينات',
                gridSize: 3
                // style: { color: '#efb24e', fontWeight: 'bold' }
              },
              {
                key: 'debt_address',
                title: 'عنوان الدائن/ة',
                gridSize: 3
                // style: { color: '#efb24e', fontWeight: 'bold' }
              },
              {
                key: 'debt_national_id',
                title: 'الرقم القومى للدائن/ة',
                gridSize: 3
                // style: { color: '#efb24e', fontWeight: 'bold' }
              },
              { key: 'debt_governorate', title: 'المحافظة', gridSize: 3 },
              { key: 'debt_center', title: ' المركز / الحي', gridSize: 3 },
              { key: 'debt_phone', title: 'رقم التلفون الدائن/ة', gridSize: 3 },
              {
                key: 'judicial_decisions',
                title: 'الاحكام القضائية',
                gridSize: 3
              },
              { key: 'cases_number', title: 'عدد القضايا', gridSize: 3 },
              {
                key: 'gharem_situation',
                title: 'موقف الغارم/الغارمة',
                gridSize: 3
              },
              {
                key: 'other_gharem_situation2',
                title: 'موقف اخر الغارم/الغارمة',
                gridSize: 3
              },
              {
                key: 'debt_reason',
                title: 'سبب الدين',
                gridSize: 3
              },
              {
                key: 'other_debt_reason',
                title: 'سبب اخر للدين',
                gridSize: 3
              },

              {
                key: 'total_debt',
                title: 'إجمالي قيمةالمديونية',
                gridSize: 3
              },
              {
                key: 'borrowing_date',
                title: 'تاريخ الاقتراض',
                gridSize: 3
              },
              {
                key: 'installments_number',
                title: 'عدد الأقساط',
                gridSize: 3
              },
              {
                key: 'installments_cost',
                title: 'قيمة القسط',
                gridSize: 3
              },

              {
                key: 'paid_installments_number',
                title: 'عدد الاقساط المسددة',
                gridSize: 3
              },
              {
                key: 'paid_installments_cost',
                title: 'قيمة الاقساط المسددة',
                gridSize: 3
              },
              {
                key: 'overdue_installments_number',
                title: 'عدد الاقساط المتاخرة',
                gridSize: 3
              },

              {
                key: 'overdue_installments_cost',
                title: 'قيمة الاقساط المتاخرة',
                gridSize: 3
              },
              {
                key: 'benefits_cost',
                title: 'قيمة الفوائد',
                gridSize: 3
              },
              {
                key: 'late_fines',
                title: 'قيمة غرامات التاخير',
                gridSize: 3
              },
              {
                key: 'debt_type',
                title: 'نوع الدين',
                gridSize: 3
              },
              {
                key: 'other_debt_type',
                title: 'نوع اخر للدين',
                gridSize: 3
              },
              {
                key: 'notes',
                title: 'ملاحظات',
                gridSize: 12
              }
            ],
            size: 12,
            gridSize: 3,
            form: task?.submission.values.debtData,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER
          }
        ],
        attachments:
          filteredAttachments && 'debtDetails' in filteredAttachments
            ? filteredAttachments.debtDetails
            : null,
        accordionType: FILE_ACCORDION_TYPE.DEFAULT,
        onAttachmentReviewed: onAttachmentReviewed,
        attachmentsKey: 'debtDetails'
      },
      {
        title: 'تقرير الباحث/ ملاحظات',
        cards: [
          {
            title: 'تقرير الباحث',
            attrs: [{ key: 'researcher_report' }],
            size: 12,
            gridSize: 9,
            style: { border: 'none', boxShadow: 'none' },
            form: task?.submission.values.researchersReport,
            cardStyle: CARD_STYLES.INNER
          },

          {
            title: 'الملاحظات',
            attrs: [
              {
                key: 'researchersReport.researcher_report',
                title: 'تقرير الباحث',
                gridSize: 12
              }
            ],
            subAttrs: [
              { key: 'users[0].username', isTitle: true },
              { key: 'comment' }
            ],
            size: 12,
            gridSize: 12,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.HIGHLIGHTED,
            form: task?.submission.values
          }
        ]
      },
      {
        title: 'المرفقات',
        cards: [
          {
            attrs: [
              { key: 'key' },
              {
                key: 'education_requiredPapers_notes',
                title: 'ملاحظات ارفاقات البرنامج',
                gridSize: 12
              },
              {
                key: 'familyCashSupport_requiredPapers_notes',
                title: 'ملاحظات ارفاقات البرنامج',
                gridSize: 12
              },
              {
                key: 'humanitarianRelief_requiredPapers_notes',
                title: 'ملاحظات ارفاقات البرنامج',
                gridSize: 12
              },
              {
                key: 'facilitateMarriage_requiredPapers_notes',
                title: 'ملاحظات ارفاقات البرنامج',
                gridSize: 12
              },
              {
                key: 'grim_requiredPapers_notes',
                title: 'ملاحظات ارفاقات البرنامج',
                gridSize: 12
              },
              {
                key: 'disability_requiredPapers_notes',
                title: 'ملاحظات ارفاقات البرنامج',
                gridSize: 12
              },
              {
                key: 'healthCare_requiredPapers_notes',
                title: 'ملاحظات ارفاقات البرنامج',
                gridSize: 12
              },
              {
                key: 'housing_requiredPapers_notes',
                title: 'ملاحظات ارفاقات البرنامج',
                gridSize: 12
              },
              {
                key: 'orphan_requiredPapers_notes',
                title: 'ملاحظات ارفاقات البرنامج',
                gridSize: 12
              }
            ],
            size: 12,
            gridSize: 12,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER,
            form: attach
          }
        ],
        attachments:
          filteredAttachments && 'docs' in filteredAttachments
            ? filteredAttachments.docs
            : null,
        accordionType: FILE_ACCORDION_TYPE.DEFAULT,
        onAttachmentReviewed: onAttachmentReviewed,
        attachmentsKey: 'docs'
      },
      {
        title: 'مرفقات الادارة المالية',
        cards: [
          {
            attrs: [{ key: 'key' }],
            size: 12,
            gridSize: 12,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER,
            form: finDocs
          }
        ],
        attachments:
          filteredAttachments && 'docs' in filteredAttachments
            ? filteredAttachments.financialDocs
            : null,
        onAttachmentReviewed: onAttachmentReviewed,
        accordionType: FILE_ACCORDION_TYPE.DEFAULT,
        attachmentsKey: 'financialDocs'
      },
      {
        title: 'مرفقات الادارة القانونية',
        cards: [
          {
            attrs: [{ key: 'key' }],
            size: 12,
            gridSize: 12,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER,
            form: legalDocs
          }
        ],
        attachments:
          filteredAttachments && 'legalDocs' in filteredAttachments
            ? filteredAttachments.legalDocs
            : null,
        onAttachmentReviewed: onAttachmentReviewed,
        accordionType: FILE_ACCORDION_TYPE.DEFAULT,
        attachmentsKey: 'legalDocs'
      },
      {
        title: 'مرفقات الادارة الطبية',
        cards: [
          {
            attrs: [{ key: 'key' }],
            size: 12,
            gridSize: 12,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER,
            accordionType: FILE_ACCORDION_TYPE.DEFAULT,
            form: medicalDocs
          }
        ],
        attachments:
          filteredAttachments && 'medicalDocs' in filteredAttachments
            ? filteredAttachments.medicalDocs
            : null,
        onAttachmentReviewed: onAttachmentReviewed,
        accordionType: FILE_ACCORDION_TYPE.DEFAULT,
        attachmentsKey: 'medicalDocs'
      },
      {
        title: 'ملاحظات/مرفقات المهندس المعماري',
        cards: [
          {
            attrs: [
              // { key: 'key' },
              {
                key: 'comment',
                title: 'التعليق'
              },
              // {
              //   key: 'date',
              //   title: 'التاريخ'
              // },
              // {
              //   key: 'author',
              //   title: 'المستخدم'
              // },
              // {
              //   key: 'step',
              //   title: 'الخطوة'
              // }
            ],
            size: 12,
            gridSize: 12,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER,
            accordionType: FILE_ACCORDION_TYPE.DEFAULT,
            form: task?.submission?.values?.architect?.comments[0],
            popoverContent: true,
            role: 'architect'
          }
        ],
        attachments:
          filteredAttachments && 'architectDocs' in filteredAttachments
            ? filteredAttachments.architectDocs
            : null,
        // onAttachmentReviewed: onAttachmentReviewed,
        accordionType: FILE_ACCORDION_TYPE.DEFAULT,
        attachmentsKey: 'architectDocs'
      },
      {
        title: 'ملاحظات مساعد الامين العام للإمداد الطبي',
        cards: [
          {
            attrs: [
              {
                key: 'comment',
                title: 'التعليق'
              },
            ],
            size: 12,
            gridSize: 12,
            style: { border: 'none', boxShadow: 'none' },
            cardStyle: CARD_STYLES.INNER,
            accordionType: FILE_ACCORDION_TYPE.DEFAULT,
            form: task?.submission?.values?.medicalSupplyAssistant?.comments[0],
            popoverContent: true,
            role: 'assistant secretary general for medical supply'
          }
        ],
      }
    ]);
  }, [filteredAttachments]);

  useEffect(() => {
    saveCardAttachment({});
    setSubmission((previousValue) => {
      return {
        ...previousValue,
        attachments: []
      };
    });
  }, []);
  
  return (
    <StylesProvider jss={jss}>
      <BlockUi
        className="block-loading-overlay-light"
        tag="div"
        blocking={taskLoading || !task}
        loader={<SyncLoader color="#4f55a28f" loading={taskLoading} />}>
        <Comments comments={timeline} user={user} />
        <PreviousSubmissions submission={task?.submission} />
        {task?.submission?.archivingDetails?.length > 0 && (
          <Alert severity="error" icon={false} className="mr-1" style={{ color: '#ff0000' }}>
            <AlertTitle style={{fontWeight:'bold', textAlign: 'center'}}>
             لقد تم وقف هذا الطلب لمدة{' '} {task?.submission?.archivingDetails?.[0]?.max_holding_time}
            </AlertTitle>
            سبب وقف الطلب :{' '}
            {task?.submission?.archivingDetails?.[0]?.archive_reason}
          </Alert>
        )}
        <Grid container direction="row" justify="center" align="center">
          <Card className="mb-2 p-2 w-100">
            <Grid container item sm={12}>
              <Grid item sm={4} className="pt-3">
                <DetailsButtons
                  setModalInfo={setModalInfo}
                  setConfirmationModal={setConfirmationModal}
                  history={history}
                  setFormModal={setFormModal}
                  setStepInputModal={setStepInputModal}
                  task={task}
                  submissionId={submissionId}
                />
              </Grid>
              <Grid item sm={5} className="pt-3">
              {
                user.roles.map((role) => role.name)
                  .some((roleName) => 
                  ['excutive director', 'super admin', 'general secertary', 'admin'].includes(roleName.toLowerCase())) 
                  && (
                    <div
                      className="badge badge-pill m-2 col-md-3"
                      style={{ fontSize: '15px' }}>
                      <span style={{ paddingRight: '15px' }}>الخطوة الحالية: </span>
                      <span>{task?.step.name}</span>
                    
                    {task?.is_assigned ? (
                      task?.users[0] && (
                        <div
                          className="badge  badge-pill m-2 col-md-3"
                          style={{ fontSize: '15px' }}>
                          <span style={{ paddingRight: '15px' }}> المسؤول : </span>
                          <span>
                            {task?.users[0]?.arabicName
                              ? task?.users[0]?.arabicName
                              : task?.users[0].username}
                          </span>
                          <br />
                        </div>
                      )
                    ) : ['Approved', 'Rejected'].includes(
                        task?.submission.status
                      ) ? (
                      <div
                        className="badge  badge-pill m-2 col-md-3"
                        style={{ fontSize: '15px' }}>
                        <span>
                          {task?.submission.status == 'Approved'
                            ? 'طلب مقبول'
                            : 'طلب مرفوض'}
                        </span>
                        <br />
                      </div>
                    ) : (
                      <div
                        className="badge  badge-pill m-2 col-md-3"
                        style={{ fontSize: '15px' }}>
                        <span>لم يتم التكليف بعد</span>
                        <br />
                      </div>
                    )}
                    </div>
                 )}
                    <div
                      className="badge badge-pill m-2 col-md-3"
                      style={{ fontSize: '20px' }}>
                      <span style={{ paddingRight: '15px' }}>
                        {task?.submission.form.name}
                      </span>
                      <br />
                    </div>
                    <div
                      className="badge badge-neutral-warning badge-pill m-2 text-warning col-md-3"
                      style={{ fontSize: '20px' }}>
                      <span style={{ paddingRight: '15px' }}>Score</span>
                      <span>{task?.submission.score}</span>
                      <br />
                    </div>
                  </Grid>
                  
              <Grid item sm={3} className="pt-3">
                <AssignmentComponent />
              </Grid>
            </Grid>
          </Card>

          {cards?.map((cardsArr, i) => (
            <GridCards
              cards={cardsArr}
              key={i}
              setPdfModals={setPdfModals}
              pdfModals={pdfModals}
              serverUrl={serverUrl}
              submission={task?.submission}
            />
          ))}

          <Tabs
            tabs={tabs}
            setPdfModals={setPdfModals}
            pdfModals={pdfModals}
            serverUrl={serverUrl}
            submission={task?.submission}
          />
          <ConfirmModal
            modal={confirmationModal}
            setModal={setConfirmationModal}
            info={modalInfo}
            callBack={handleAssignment}></ConfirmModal>
          {formModal && (
            <FormModal
              open={formModal}
              setOpen={setFormModal}
              submissionAttachments={task?.submission.attachments}
              transitions={task?.step.transitions}
              submission={task?.submission}
              lastComment={task?.last_task?.comment}
              task_id={task?.id}
            />
          )}
          {stepInputModal && (
            <StepInputModal
              open={stepInputModal}
              setOpen={setStepInputModal}
              sections={task?.step.sections}
              submission={task?.submission}
              task={task}
            />
          )}
          {
             user.roles.map((role) => role.name)
             .some((roleName) => 
             ['excutive director', 'super admin', 'general secertary', 'admin'].includes(roleName.toLowerCase())) 
             && (
               <TaskTimeline timeline={timeline} />
             )
          }
        </Grid>
      </BlockUi>
    </StylesProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    task: state.details.task,
    timeline: state.details.timeline,
    loading: state.details.loading,
    taskLoading: state.details.taskLoading,
    user: state.auth.user,
    users: state.details.users
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTaskDetails: (submission_id) =>
      dispatch(getTaskDetails({ submission_id, task_status: 'Pending' })),
    getTimeline: (params) => dispatch(getTimeline(params)),
    assignTask: (body) => dispatch(assignTask(body)),
    saveCardAttachment: (attachment) =>
      dispatch(saveCardAttachment(attachment)),
    getUsers: (params) => dispatch(getUsers(params)),
    handleTaskAssignment: (params) => dispatch(handleTaskAssignment(params)),
    setSubmission: (submissionDetails) =>
      dispatch(setSubmission(submissionDetails))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
